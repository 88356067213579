const host = 'https://ks-api-dev.putradigitalhandal.com/';
// const host = 'http://localhost/ksd-api/'
export default class Gvar {
    static host = host;
    static server = host;
    static tutorialSteps = [{judul:""}];
    static pathImage = host+"/img/";
    static titlePage = "";
    static idMember = "";
    static tabActive = "Dashboard";
    static appName = "Fifapay";
    static senderMail = "cs@fifapay.co.id";
    static whatsappNumber = "+628118996610";
    static webSender = "https://fifapay.co.id";

    static namaMember = '-';
    static saldo = 0;

    static secretMemory = 'XKHAS50MH6';
    static dataMember = null;
    static badgeContent = null;
    static secretForgotPassword = 'F01290TP@SSW012D';
    static isProduction = true;
    static fontColor = '#73789e';
    static baseName = "/";
    static bgColorPage = '#f3f3f3';
}